@import "./styles/fonts/normal.scss";
@import "./styles/root.scss";
@import "./styles/oneword.scss";

/*---------------Boot Strap-------------------*/
body,
html {
  height: 100%;
}
body {
  letter-spacing: 0;
  font-weight: 400;
  margin: 0;
  font-style: normal;
  font-family: "Helvetica Neue", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga";
  color: var(--rgb08);
  font-size: 18px;
  line-height: 1.4;
}

.open-app-modal {
  overflow: hidden;
}

button:disabled,
input:disabled {
  opacity: 0.5;
  pointer-events: none;
}
